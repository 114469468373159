body {
  color: #1b1b1b;
  display: flex;
  flex-direction: column;
  font-family: Inter, sans-serif;
  min-height: 100vh;
  overflow-x: hidden;
}

.main {
  flex: 1;
}

a:hover {
  color: #1851B4;
  text-decoration: underline;
}

.content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 10px;
}

h1 {
  font-size: 60px;
  font-weight: 800;
  line-height: 64px;
}

h2 {
  font-size: 40px;
  font-weight: 800;
  line-height: 44px;
}

h3 {
  font-size: 28px;
  font-weight: 800;
  line-height: 32px;
}

h4 {
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
}

p.body1 {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

p.body2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

p.body3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media(max-width:768px) {
  h1 {
    font-size: 40px;
    font-weight: 800;
    line-height: 44px;
  }

  h2 {
    font-size: 28px;
    font-weight: 800;
    line-height: 32px;
  }

  p.body1 {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
}

.semibold {
  font-weight: 600 !important;
}

.hidden {
  display: none;
}

.text-center {
  text-align: center;
}

a.no-underline {
  text-decoration: none;
}

.button {
  background-color: #2c6bed;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
}

.button a {
  color: #fff;
}

.button[disabled] {
  color: #1b1b1b;
}

.button:hover, .button:hover a {
  background-color: #1851B4;
  color: #fff;
}

.button2 {
  background-color: #fff;
  border-radius: 8px;
  border: 2px solid #2c6bed;
  color: #2c6bed;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.button2 a {
  color: #2c6bed;
}

.button2:hover, .button2:hover a {
  background-color: #fff;
  border-color: #1851B4;
  color: #1851B4;
}

.button-white {
  background-color: #fff;
  color: #2C6BED;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  padding: 0 20px;
}

.button-white a {
  color: #2C6BED;
}

.button-white:hover, .button-white:hover a {
  background-color: #fff;
  color: #1851B4;
}

.signal-logo {
  height: 28px;
  vertical-align: middle;
}

body.has-navbar-fixed-top, html.has-navbar-fixed-top {
  padding-top: 52px;
}

.signal-navbar {
  border-bottom: 1px solid #ebeaeb;
}

@media(min-width:1024px) {
  body.has-navbar-fixed-top, html.has-navbar-fixed-top {
    padding-top: 85px;
  }

  .signal-navbar {
    padding: 16px 0;
  }

  .signal-logo {
    height: 44px;
  }
}

.signal-navbar .navbar-brand {
  align-items: center;
  padding-left: 18px;
}

@media(min-width:1024px) {
  .signal-navbar .navbar-brand {
    padding: 0px;
  }
}

.navbar-menu.is-active {
  position: absolute;
  width: 100%;
}

.navbar-end {
  padding-left: 6px;
}

.signal-navbar .navbar-item, .signal-navbar .navbar-link {
  color: #1b1b1b;
  font-size: 16px;
}

.navbar-link:not(.is-arrowless)::after {
  border-color: #1b1b1b;
}

.navbar-dropdown {
  max-height: 200px;
  overflow: auto;
}

@media(max-width:1024px) {
  .navbar-link:not(.is-arrowless)::after {
    display: none;
  }
}

.signal-navbar a.navbar-item:hover {
  background-color: inherit;
  text-decoration: none;
}

.signal-navbar a.navbar-item:hover {
  color: #2C6BED;
}

.signal-navbar .language-selector .fas {
  padding-right: 4px;
}

.footer {
  background-color: #3C3744;
  color: #E9E9E9;
  font-size: 16px;
  line-height: 22px;
}

.footer strong {
  color: #E9E9E9;
  display: block;
  font-weight: 600;
  margin-bottom: 12px;
}

.footer a {
  color: #E9E9E9;
}

.footer a:hover {
  text-decoration: underline;
}

/* rtl */

html[dir=rtl] {
  .signal-logo {
    margin-left: 40px;
  }

  .navbar-end {
    justify-content: flex-start;
    margin-left: 0;
    margin-right: auto;
  }

  .signal-navbar .language-selector .fas {
    padding-left: 4px;
    padding-right: 0;
  }
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: local('Inter-Regular'),
    url("/assets/fonts/inter/Inter-Regular.woff2") format("woff2"),
    url("/assets/fonts/inter/Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: local('Inter-Italic'),
    url("/assets/fonts/inter/Inter-Italic.woff2") format("woff2"),
    url("/assets/fonts/inter/Inter-Italic.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  font-display: swap;
  src: local('Inter-SemiBold'),
    url("/assets/fonts/inter/Inter-SemiBold.woff2") format("woff2"),
    url("/assets/fonts/inter/Inter-SemiBold.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 600;
  font-display: swap;
  src: local('Inter-SemiBoldItalic'),
    url("/assets/fonts/inter/Inter-SemiBoldItalic.woff2") format("woff2"),
    url("/assets/fonts/inter/Inter-SemiBoldItalic.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 800;
  font-display: swap;
  src: local('Inter-ExtraBold'),
    url("/assets/fonts/inter/Inter-ExtraBold.woff2") format("woff2"),
    url("/assets/fonts/inter/Inter-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 800;
  font-display: swap;
  src: local('Inter-ExtraBoldItalic'),
    url("/assets/fonts/inter/Inter-ExtraBoldItalic.woff2") format("woff2"),
    url("/assets/fonts/inter/Inter-ExtraBoldItalic.woff") format("woff");
}

/* foundation */

.foundation {
  &.navbar-brand a {
    color: #1b1b1b;
    font-size: 36px;
    font-weight: 800;
    line-height: 48px;

    &:hover {
      text-decoration: none;
    }
  }

  &.gray-background {
    background-color: #f6f6f6;
  }

  .hero-body {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .hero-image {
    padding-bottom: 0.35rem;
    padding-right: 0;
  }

  .why-foundation {
    margin-bottom: 140px;

    h2 {
      margin-top: 16px;
    }
  }

  .donate {
    margin-top: 40px;
  }

  &.is-medium {
    padding-bottom: 120px;
    padding-left: 64px;
    padding-right: 64px;
    padding-top: 120px;
  }

  &.is-medium.mission {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .icon-wrapper {
    background-color: #2C6BED;
    border-radius: 40px;
    display: inline-block;
    height: 72px;
    margin-bottom: 16px;
    padding: 14px;
    width: 72px;

    i {
      color: #fff;
      font-size: 34px;
      line-height: 44px;
    }
  }

  .features {
    margin-top: 69px;

    h3 {
      margin-bottom: 16px;
    }
  }

  h1 {
    font-size: 36px;
    font-weight: 400;
    line-height: 48px;
  }

  h2 {
    margin-bottom: 64px;
  }

  .biography-container {
    margin-bottom: 140px;

    img {
      border-radius: 8px;
    }
  }

  .last-bio {
    margin-bottom: 0;
  }

  .biography {
    padding-left: 36px;

    h3 {
      margin-bottom: 16px;
    }

    p.body1 {
      margin-bottom: 40px;
    }
  }

  @media(max-width:1024px) {
    &.navbar-brand a {
      font-size: 20px;
      line-height: 24px;
    }
    
    .hero-padded {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    h1 {
      font-size: 24px;
      line-height: 38px;
    }

    h2 {
      margin-bottom: 24px;
    }

    &.is-medium {
      padding-bottom: 80px;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-top: 80px;
    }

    .features {
      margin-top: 0;
    }

    .mission-container {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

    .biography-container { 
      margin-bottom: 70px;
    }

    .biography {
      padding-left: 0.75rem;
    }

    .last-bio {
      margin-bottom: 0;
    }
  }
}

@media(min-width: 1024px) {
  .container>.navbar .navbar-brand, .navbar>.container .navbar-brand {
    margin-left: 0;
  }
}
